
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.listing-filters {
    padding-top: 20px;
    width: 100%;
    border: 1px solid rgba($insurance-super-saver-blue-aster, 0.2);
    padding: 0 14px;
    border-radius: 2px;
    margin-bottom: 2.5rem;
    .toggle-button {
        text-align: center;
        color: $insurance-super-saver-blue-aster;
        margin: 7px 0;
        @include lg {
            display: none;
        }
    }
    .inner {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        transition: all 0.2s ease-in-out;

        .questions {
            flex-grow: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .row {
                display: flex;
                align-items: center;
                margin-right: 0px;
                flex-wrap: wrap;
                width: 100%;
                margin-bottom: 10px;
                &:last-child {
                    margin-right: 0;
                }
                &.boxes {
                    justify-content: space-between;
                    .question {
                        border: 1px solid #b7b7b7;
                        height: 40px;
                        margin-right: 0px;
                        width: 47%;
                        margin-bottom: 10px;
                        border-radius: 5px;

                        &:first-child {
                            @include lg {
                                border-radius: 5px 0 0 5px;
                            }
                        }

                        &:last-child {
                            @include lg {
                                border-radius: 0px 5px 5px 0px;
                            }
                        }

                        &:not(:last-child) {
                            @include lg {
                                border-right: 0;
                            }
                        }

                        @include lg {
                            width: auto;
                            height: 47px;
                            margin-bottom: 0px;
                            border-radius: 0px;
                        }
                    }
                    @include lg {
                        justify-content: flex-start;
                    }
                }
                @include lg {
                    margin-right: 20px;
                    width: auto;
                }
            }
        }

        .submit {
            margin-top: 10px;
            text-align: right;
            width: 100%;
            button {
                border: 1px solid $insurance-super-saver-blue-aster;
                color: $insurance-super-saver-blue-aster;
                padding: 5px 30px;
                border-radius: 4px;
                transition: all 0.2s ease-in-out;
                display: block;
                width: 100%;

                &:hover {
                    background-color: $insurance-super-saver-blue-aster;
                    color: white;
                }
            }
            @include lg {
                margin-top: 0px;
                width: auto;
                margin-left: auto;
            }
        }

        @include lg {
            height: auto !important;
            opacity: 1 !important;
            margin-bottom: 0 !important;
            justify-content: flex-end;
        }
    }
    @include lg {
        padding: 30px 30px;
        border-radius: 2px;
        margin-bottom: 3rem;
    }
}

.select-question {
    display: flex;
    background-color: white;
    align-items: center;
    margin-right: 0px;
    position: relative;
    background-image: linear-gradient(45deg, transparent 50%, black 50%),
        linear-gradient(135deg, black 50%, transparent 50%);
    background-position: calc(100% - 12px) 17px, calc(100% - 7px) 17px,
        calc(100% - 2.5em) 3px;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    padding: 10px 10px 10px 10px;

    label {
        color: $insurance-super-saver-assault;
        @apply text-xs lg:text-sm font-light mr-2;
    }
    .value {
        padding-right: 5px;
        color: $insurance-super-saver-assault;
        @apply text-xs lg:text-sm;
    }
    select {
        border: none;
        outline: none;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        &:focus {
            outline: none;
            border: none;
        }
    }
    &#coverageAmount,
    &#householdIncome {
        width: 100%;
        @include lg {
            width: auto;
            .value {
                min-width: 111px;
            }
        }
    }
    &#gender {
        .value {
            @include lg {
                min-width: 58px;
            }
        }
    }
    &#birthDate {
        .value {
            @include lg {
                min-width: 41px;
            }
        }
    }
    &#weight {
        .value {
            @include lg {
                min-width: 32px;
            }
        }
    }
    &#height {
        .value {
            @include lg {
                min-width: 39px;
            }
        }
    }
    &#vehicleCount {
        .value {
            @include lg {
                min-width: 24px;
            }
        }
    }
    @include lg {
        padding: 10px 20px 10px 15px;
        background-position: calc(100% - 12px) 21px, calc(100% - 7px) 21px,
            calc(100% - 2.5em) 3px;
    }
}

.toggle-question {
    margin-right: 20px;
    margin-bottom: 10px;
    input {
        position: absolute;
        opacity: 0;

        &:checked + label .checkbox svg path {
            stroke-dashoffset: 0;
        }
        &:checked + label .checkbox {
            background-color: $insurance-super-saver-blue-aster;
        }

        & + label {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            cursor: pointer;
            color: $insurance-super-saver-assault;
            @apply text-xs lg:text-sm;
            .checkbox {
                display: block;
                border: 2px solid $insurance-super-saver-blue-aster;
                width: 18px;
                height: 18px;
                border-radius: 2px;
                cursor: pointer;
                transition: all 0.2s ease;
                margin-right: 5px;
                padding: 2px;

                svg {
                    pointer-events: none;
                    path {
                        fill: none;
                        stroke: white;
                        stroke-width: 6px;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 100;
                        stroke-dashoffset: 101;
                        transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
                    }
                }
                @include lg {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
    &:last-of-type {
        margin-right: 0;
    }
    @include lg {
        margin-bottom: 0;
    }
}

.text-question {
    border: 1px solid #b7b7b7;
    color: $insurance-super-saver-assault;
    height: 40px;
    margin-right: 0px;
    border-radius: 5px;
    padding: 1px;
    input {
        height: 100%;
        width: 100%;
        padding: 10px;
        &:focus {
            outline: none;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type="number"] {
            -moz-appearance: textfield;
        }
        @include lg {
            padding: 10px 20px 10px 15px;
        }
    }
    @include lg {
        height: 47px;
    }
    &#zipCode {
        width: 100%;
    }
}
