
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.selling-box {
    .insurance-type {
        .insurance-radio {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -0.5rem;
            .radio-item {
                width: calc(50% - 1rem);
                margin: 0 0.5rem;
                margin-top: 0.75rem;
                label {
                    border: 1px solid lavender;
                    border-radius: 4px;
                    height: 100%;
                    cursor: pointer;
                    text-align: center;
                    min-height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    user-select: none;

                    .icon {
                        img {
                            width: 25px;
                            height: auto;
                            margin: 0 auto;
                            height: auto;
                            @include lg {
                                width: 40px;
                            }
                        }
                    }
                    .title {
                        margin-top: 10px;
                        text-transform: capitalize;
                    }
                    @include lg {
                        min-height: 120px;
                    }
                }
                input {
                    &:checked + label {
                        border-color: $insurance-super-saver-blue-aster;
                        background: #f4f9fa;
                        border-width: 2px;
                    }
                    &:disabled + label {
                        opacity: 0.5;
                        cursor: auto;
                    }
                }
                @include lg {
                    width: calc(25% - 1rem);
                    margin-top: 0;
                }
            }
        }
    }
    .form {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;
        flex-direction: column;
        position: relative;
        padding-bottom: 10px;

        .filters {
            padding: 0;
            border: none;
            margin-top: 15px !important;
            width: calc(100% + 10px);
            margin-bottom: 0 !important;
            .text-question {
                &:first-of-type {
                    width: 100%;
                    border: 2px solid lavender !important;
                    padding: 1px;
                    height: 100%;
                    margin: 0 5px;
                    margin-bottom: 10px;
                    &:focus-within {
                        border-color: $insurance-super-saver-blue-aster !important;
                    }
                    input {
                        padding: 15px;
                        @include lg {
                            padding: 20px;
                        }
                    }
                    @include lg {
                        margin: 0 10px;
                        margin-bottom: 15px;
                    }
                }
            }
            .select-question {
                border: 2px solid lavender !important;
                margin: 0 5px !important;
                border-radius: 4px !important;
                margin-bottom: 10px !important;
                width: calc(50% - 10px) !important;
                min-width: fit-content !important;
                padding-right: 20px;
                @include lg {
                    margin: 0 10px !important;
                    margin-bottom: 15px !important;
                    width: auto !important;
                    padding: 10px 20px 10px 15px;
                }
            }
            .toggle-question {
                border: none !important;
                margin: 0 5px;
                margin-bottom: 15px !important;
                display: flex;
                align-items: center;
                width: calc(50% - 10px) !important;
                @include lg {
                    margin: 0 30px;
                    width: auto !important;
                }
            }

            @include lg {
                width: calc(100% + 20px);
            }
        }

        .button {
            width: 100%;
            margin-top: 10px;
            button {
                background-color: $insurance-super-saver-blue-aster;
                color: white;
                padding: 10px 30px;
                border-radius: 5px;
                width: 100%;
                height: 100%;
                display: block;
                height: 53px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: $insurance-super-saver-assault;
                }
                &:disabled {
                    opacity: 0.6;
                    cursor: default;
                    pointer-events: none;
                }
                @include lg {
                    min-width: 180px;
                    padding: 15px 30px;
                    height: auto;
                }
            }
            @include lg {
                width: 33.3%;
                margin-top: 20px;
            }
        }

        .error {
            position: absolute;
            bottom: -1px;
            left: 50%;
            transform: translateX(-50%) translateY(100%);
            color: #d10202;
            @apply text-xs font-light;
        }
    }
}
