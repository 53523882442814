
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.header {
    ul {
        li {
            a {
                position: relative;
                color: $insurance-super-saver-blue-aster;
                transition: all 0.2s ease-in-out;
                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: $insurance-super-saver-blue-aster;
                    transition: all 0.2s ease-in-out;
                }
                &:hover {
                    color: $insurance-super-saver-lucea;
                    &:before {
                        background-color: $insurance-super-saver-lucea;
                    }
                }
            }
        }
    }
    .state {
        color: $insurance-super-saver-blue-aster;
        @apply capitalize text-xs lg:text-sm;
    }
}
